export const getNameFromBp = (baseProduct) => {
  const { newBaseProduct, name } = baseProduct
  if (newBaseProduct) {
    const { fr } = name
    return fr
  }
  return name
}

const arbitrateMargin = (margin) => {
  const rounded = Math.round(margin)
  if (Math.abs(rounded - margin) <= 0.02) {
    return rounded
  }
  return margin
}

const formatDescription = (
  color,
  markingColor,
  technicsName,
  carbonImpact,
  constitution,
  delayDelivery,
  weight,
  originProduct,
  dimensions,
  refPanopli,
  shopifyHandle,
) => {
  try {
    const { name = 'N/C' } = color
    const parts = [
      refPanopli && `Référence : ${refPanopli}`,
      name && `Couleur : ${name}`,
      technicsName && `Marquage : ${technicsName.toString()}, ${markingColor}`,
      constitution && `Constitution : ${constitution}`,
      originProduct && `Origine du produit : ${originProduct}`,
      delayDelivery && `Délai de livraison : ${delayDelivery}`,
      dimensions && `Dimensions : ${dimensions}`,
      weight && `Poids : ${weight}kg`,
      carbonImpact && `Empreinte carbone : ${carbonImpact}kgCO2eq`,
      shopifyHandle && `Lien du produit : https://www.panopli.co/produits-catalogue/${shopifyHandle}`,
    ]
    return parts.filter(Boolean).join('\n')
  } catch (e) {
    console.log('formatDescription - error', {
      error: e,
      message: e.message,
      color,
      markingColor,
      technicsName,
      dimensions,
      // Ajout des autres variables pour debug
      stack: e.stack,
    })
    return ''
  }
}

export const formatProductForHubpost = (item, providers) => {
  const {
    name,
    idPanopli,
    technicsSelected,
    color,
    unitPrice,
    unitCost,
    qty,
    tags = [],
    providerId,
    supplierProductUrl,
    carbonImpact,
    markingZone,
    sizeRepartition,
    markingColor,
    image,
    originProduct,
    dimension,
    constitution,
    delayDelivery,
    weight,
    shopifyHandle,
  } = item
  const foundProvider = providers.find((provider) => providerId === provider._id) || {}
  const technicsName = technicsSelected
    .map((technic) => technic.name)
    .toString()

  return {
    name,
    hs_sku: idPanopli,
    supplier_name: foundProvider.name || '',
    url_supplier_product: supplierProductUrl,
    quantity: qty,
    hs_cost_of_goods_sold: parseFloat(unitCost),
    price: parseFloat(unitPrice),
    technique_de_marquage: technicsSelected
      .map((technic) => technic.name)
      .toString(),
    image_produit: image,
    couleur_de_marquage: markingColor,
    couleur_du_produit: color.name,
    produit_responsable: tags.includes('Eco-responsable')
      ? 'Responsable'
      : 'Non responsable',
    product_origin: originProduct,
    dimensions_du_marquage: '',
    dimension_produit: dimension,
    autres_precisions: '',
    empreinte_carbone: String(carbonImpact),
    zone_de_marquage: markingZone,
    repartition_des_tailles: sizeRepartition,
    description: formatDescription(
      color,
      markingColor,
      technicsName,
      carbonImpact,
      constitution,
      delayDelivery,
      weight,
      originProduct,
      dimension,
      idPanopli,
      shopifyHandle,
    ),
  }
}

export const formatProductForCatalogCart = (
  baseProduct,
  unitPrice,
  cost,
  qty,
  technicsSelected,
  color,
  sizeRepartition,
  markingZone,
  markingColor,
) => {
  const {
    images,
    idPanopli,
    tags,
    providerId,
    supplierProductUrl,
    carbonImpact,
    caracteristics = {},
    shopifyHandle
  } = baseProduct
  const { global = 'NC' } = carbonImpact
  const {
    originProduct = 'NC',
    dimension = 'NC',
    constitution = 'NC',
    delayDelivery = 'NC',
    weight = 'NC',
  } = caracteristics
  const totalPrice = unitPrice * 100 * qty
  const totalCost = cost * 100 * qty
  const margin = arbitrateMargin(((totalPrice - totalCost) / totalPrice) * 100)
  return {
    cartId: String(Math.random()),
    idPanopli,
    name: getNameFromBp(baseProduct),
    unitPrice: parseFloat(unitPrice),
    qty,
    totalPrice: unitPrice * qty,
    totalCost: cost * qty,
    color,
    tags,
    technicsSelected: technicsSelected.map((t) => t),
    image: images.find(Boolean)?.src,
    unitCost: cost,
    margin,
    providerId,
    supplierProductUrl,
    markingZone,
    sizeRepartition,
    markingColor,
    originProduct,
    dimension,
    constitution,
    delayDelivery,
    weight,
    carbonImpact: global,
    shopifyHandle,
  }
}

const getDeliveryPriceFromGrid = (totalValue, deliveryGrid) => deliveryGrid
  .filter((price) => price.threshold <= totalValue)
  .reverse()
  .find(Boolean)

export const calculateTotalProvidersDelivery = (
  reducedProviders,
  stateProviders,
) => reducedProviders
  .map((provider) => {
    const concernedProvider = stateProviders.find(
      (pv) => pv._id === provider.id,
    )

    if (
      !concernedProvider
        || !concernedProvider?.deliveryGrid
        || !concernedProvider.deliveryGrid.length
    ) {
      return false
    }
    const { totalValue } = provider
    const delivery = getDeliveryPriceFromGrid(
      totalValue,
      concernedProvider.deliveryGrid,
    )
    return {
      ...provider,
      name: concernedProvider.name,
      deliveryPrice: delivery.price,
    }
  })
  .filter(Boolean)

export const regroupProductsByProdiver = (cartProducts) => cartProducts.reduce((acc, currentProduct) => {
  const { providerId, totalCost } = currentProduct
  // on vérifie qu'on n'a pas déjà ce providerId dans l'accumulateur
  const presentProvider = acc.find((provider) => provider.id === providerId)
  if (presentProvider) {
    const { totalValue } = presentProvider
    presentProvider.totalValue = totalValue + totalCost
    return acc
  }
  acc.push({ id: providerId, totalValue: totalCost, inacurate: !providerId })
  return acc
}, [])

export const getTotalDeliveryPrice = (cartProducts, providers) => {
  const reduced = regroupProductsByProdiver(cartProducts)
  const calculated = calculateTotalProvidersDelivery(reduced, providers)
  return calculated
}
